<script>
import ChapterManageForm from "./ChapterManageForm.vue";
import { GetChapterSlugById, GetChapter } from "../chapterManageOperations.gql";
import * as ChapterApi from "../chapterManageApi.js";
import { convertFileToBase64String } from "@/utils/formatting/files.js";

export default {
  name: "ChapterManageFormPipe",
  components: {
    ChapterManageForm
  },
  props: {
    chapterId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      slug: undefined,
      initialDomains: [],
      chapterForm: {
        chapterId: undefined,
        address: undefined,
        title: undefined,
        description: undefined,
        descriptionJson: undefined,
        lat: undefined,
        lng: undefined,
        photoData: undefined,
        photoUrl: undefined,
        domains: "",
        meetupGroupId: undefined,
        meetupGroupUrlname: undefined,
        meetupMemberCount: undefined,
        foundedAt: undefined
      },
      isEditing: false
    };
  },
  apollo: {
    slug: {
      query: GetChapterSlugById,
      variables() {
        return {
          id: this.chapterForm.chapterId
        };
      },
      skip() {
        return !this.chapterForm.chapterId;
      },
      update(data) {
        if (data && data.result) {
          return data.result.slug;
        }
        return "";
      }
    },
    resultChapter: {
      query: GetChapter,
      variables() {
        return {
          chapterId: this.chapterId
        };
      },
      skip() {
        return !this.chapterId;
      },
      result({ data }) {
        if (data && data.resultChapter) {
          const chapter = data.resultChapter;
          this.chapterForm = {
            ...this.chapterForm,
            chapterId: this.chapterId,
            title: chapter.title,
            address: chapter.place?.city,
            descriptionJson: chapter.description_json,
            lat: chapter.place?.lat,
            lng: chapter.place?.lng,
            photoUrl: chapter.photo_url ? `${chapter.photo_url}?t=${Date.now()}` : undefined,
            domains: chapter.chapter_domains?.map(d => d.domain).join(",") || "",
            foundedAt: chapter.founded_at?.split("T")[0],
            meetupGroupId: chapter.meetup_chapter_map?.meetup_group_id,
            meetupGroupUrlname: chapter.meetup_chapter_map?.meetup_group_urlname,
            meetupMemberCount: parseInt(chapter.meetup_chapter_map?.meetup_member_count)
          };
          this.initialDomains = chapter.chapter_domains?.map(d => d.domain) || [];
          this.isEditing = true;
        }
      }
    }
  },
  methods: {
    onPlaceSelected(place) {
      this.chapterForm.address = place.formatted_address;
      this.chapterForm.lat = place.geometry.location.lat();
      this.chapterForm.lng = place.geometry.location.lng();
    },
    async onFileSelected(file) {
      try {
        const base64Data = await convertFileToBase64String(file);
        this.chapterForm.photoData = base64Data;
      } catch (error) {
        this.$root.$emit("universal-error-message", "Error converting file to Base64");
      }
    },
    async createChapter() {
      const { descriptionJson, lat, lng, title } = this.chapterForm;
      const result = await ChapterApi.createChapter(descriptionJson, lat, lng, title);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async updateChapter() {
      const { chapterId, descriptionJson, lat, lng, title, foundedAt } = this.chapterForm;
      const result = await ChapterApi.editChapter(chapterId, descriptionJson, lat, lng, title, foundedAt);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async uploadChapterPhoto() {
      const { chapterId, photoData } = this.chapterForm;
      const result = await ChapterApi.uploadChapterPhoto(chapterId, photoData);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async addChapterDomain(domain) {
      const { chapterId } = this.chapterForm;
      const result = await ChapterApi.addChapterDomain(chapterId, domain);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async removeChapterDomain(domain) {
      const { chapterId } = this.chapterForm;
      const result = await ChapterApi.removeChapterDomain(chapterId, domain);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onSave() {
      let result;
      if (this.isEditing) {
        result = await this.updateChapter();
      } else {
        result = await this.createChapter();
      }

      if (!result.success) {
        return;
      }

      if (!this.isEditing) {
        this.chapterForm.chapterId = result.result.id;
        this.$emit("created", this.chapterForm.chapterId);
      }

      // Handle domain changes
      const newDomains = this.chapterForm.domains ? this.chapterForm.domains.split(",").map(d => d.trim()) : [];
      const domainsToAdd = newDomains.filter(d => !this.initialDomains.includes(d));
      const domainsToRemove = this.initialDomains.filter(d => !newDomains.includes(d));

      // Remove old domains
      for (const domain of domainsToRemove) {
        const removeResult = await this.removeChapterDomain(domain);
        if (!removeResult.success) {
          this.$root.$emit("universal-error-message", removeResult.error);
        }
      }

      // Add new domains
      for (const domain of domainsToAdd) {
        const addResult = await this.addChapterDomain(domain);
        if (!addResult.success) {
          this.$root.$emit("universal-error-message", addResult.error);
        }
      }

      // Handle meetup group details
      if (this.chapterForm.meetupGroupId && this.chapterForm.meetupGroupUrlname && this.chapterForm.meetupMemberCount) {
        const meetupResult = await ChapterApi.upsertMeetupGroup(
          this.chapterForm.chapterId,
          this.chapterForm.meetupGroupId,
          this.chapterForm.meetupGroupUrlname,
          parseInt(this.chapterForm.meetupMemberCount)
        );
        if (!meetupResult.success) {
          this.$root.$emit("universal-error-message", meetupResult.error);
        }
      }

      if (this.chapterForm.photoData) {
        const uploadResult = await this.uploadChapterPhoto();
        if (!uploadResult.success) {
          return;
        }
      }

      this.$root.$emit("universal-success-message", "Saved");
      await this.$apollo.queries.resultChapter.refetch();
    }
  }
};
</script>

<template>
  <ChapterManageForm
    :value="chapterForm"
    :is-editing="isEditing"
    @input="chapterForm = $event"
    @place-selected="onPlaceSelected"
    @file-selected="onFileSelected"
    @save="onSave"
  />
</template>
