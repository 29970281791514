<script>
import { SuawMainContent } from "@/components";
import ChapterManageFormPipe from "./components/ChapterManageFormPipe.vue";

export default {
  name: "ChapterManage",
  components: { 
    SuawMainContent,
    ChapterManageFormPipe
  },
  computed: {
    chapterId() {
      return this.$route.params.chapterId;
    }
  }
};
</script>

<template>
  <SuawMainContent size="medium">
    <ChapterManageFormPipe :chapter-id="chapterId" />
  </SuawMainContent>
</template>
