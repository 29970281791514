<script>
import { SuawForm, SuawSummary, SuawTextInput, SuawLocationInput, SuawFileUpload, SuawTextArea, SuawSearchInput, SuawThumbnail, SuawButton } from "@/components";
import { required } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "@/utils/api/validationErrors";

export default {
  name: "ChapterManageForm",
  components: { 
    SuawForm, 
    SuawSummary, 
    SuawTextInput, 
    SuawLocationInput, 
    SuawFileUpload, 
    SuawTextArea, 
    SuawSearchInput,
    SuawThumbnail,
    SuawButton
  },
  props: {
    isEditing: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required: true
    }
  },
  validations: {
    value: {
      title: { required },
      address: { required },
      descriptionJson: { required }
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    onPlaceSelected(place) {
      this.$emit('place-selected', place);
    },
    onFileSelected(file) {
      this.$emit('file-selected', file);
    },
    onSaveClicked() {
      this.$v.value.$touch();
      if (this.$v.value.$invalid) {
        this.$root.$emit("universal-error-message", "The chapter form is invalid.");
        return;
      }
      this.$emit('save');
    },
    updateField(field, value) {
      const updatedForm = { ...this.value, [field]: value };
      this.$emit('input', updatedForm);
    }
  }
};
</script>

<template>
  <SuawForm>
    <template #form>
      <div class="header">
        <SuawSummary summary-type="form" :show-primary-button="false" :heading="isEditing ? 'Edit Chapter' : 'Create New Chapter'" />
        <SuawButton :button-text="isEditing ? 'Save Changes' : 'Create'" type="primary" size="large" @click="onSaveClicked" />
      </div>

      <SuawTextInput
        id="chapterName"
        :value="value.title"
        type="text"
        label="Chapter Name"
        is-required
        placeholder="How the chapter will be displayed to the world."
        :state="formFieldState($v, 'value', 'title')"
        :error-message="validateErrors($v.value.title, 'Chapter Name')"
        @input="updateField('title', $event)"
        @blur="$v.value.title.$touch()"
      />

      <SuawSearchInput
        :value="value.address"
        :cities-only="true"
        placeholder="Enter a city"
        :is-required="true"
        :state="formFieldState($v, 'value', 'address')"
        :error-message="validateErrors($v.value.address, 'Chapter city')"
        :use-google="true"
        :show-icon="true"
        @input="updateField('address', $event)"
        @blur="$v.value.address.$touch()"
        @item-select="onPlaceSelected"
      />

      <SuawLocationInput
        label="Region"
        :location-name="value.title"
        :location-address="value.address"
        :lat="value.lat"
        :lng="value.lng"
        :zoom="11"
        :editable="true"
      />

      <SuawFileUpload upload-title="Upload Chapter Image" @file-selected="onFileSelected" />
      <SuawThumbnail v-if="value.photoUrl" size="fit" :content="value.photoUrl" />

      <SuawTextArea 
        id="chapterDesc" 
        :value="value.descriptionJson"
        use-tip-tap 
        label="Description" 
        placeholder="Describe the chapter"
        :state="formFieldState($v, 'value', 'descriptionJson')"
        :error-message="validateErrors($v.value.descriptionJson, 'Description')"
        @input="updateField('descriptionJson', $event)"
        @blur="$v.value.descriptionJson.$touch()"
      />

      <SuawTextInput
        :value="value.domains"
        label="Email Domain(s)"
        placeholder="People in these domains (separated by commas) are automatically added to this chapter."
        @input="updateField('domains', $event)"
      />

      <SuawTextInput
        :value="value.meetupGroupId"
        type="text"
        label="Meetup Group ID"
        placeholder="The ID of the associated Meetup group"
        @input="updateField('meetupGroupId', $event)"
      />

      <SuawTextInput
        :value="value.meetupGroupUrlname"
        type="text"
        label="Meetup Group URL Name"
        placeholder="The URL name of the associated Meetup group"
        @input="updateField('meetupGroupUrlname', $event)"
      />

      <SuawTextInput
        :value="value.meetupMemberCount"
        type="number"
        label="Meetup Member Count"
        placeholder="The number of members in the Meetup group"
        @input="updateField('meetupMemberCount', $event)"
      />

      <SuawTextInput
        :value="value.foundedAt"
        type="date"
        label="Founded Date"
        placeholder="When was this chapter founded?"
        @input="updateField('foundedAt', $event)"
      />
    </template>
  </SuawForm>
</template>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
</style>
