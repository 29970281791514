import { CreateChapter, UploadChapterPhoto, AddChapterDomain, EditChapter, RemoveChapterDomain, UpsertMeetupGroup } from "./chapterManageOperations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
// import { Logger, DEBUG } from "@/utils/plugins/logging";
import { call } from "@/utils/api/base.js";

export async function createChapter(descriptionJson, lat, lng, title) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.ALREADY_DELETED, "This chapter was previously created and deleted."],
    [BE.ALREADY_EXISTS, "A chapter with this name already exists."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.NOT_EXIST, "The GPS location was not correct."],
    [BE.INSERT_FAILED, "Error creating this chapter."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: CreateChapter,
        variables: {
          descriptionJson,
          lat,
          lng,
          title
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function uploadChapterPhoto(chapterId, photoBase64Image) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Please choose a file to upload."],
    [BE.NOT_EXIST, "Chapter does not exist."],
    [BE.INVALID_FORMAT, "The file you selected was not in a valid format."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Your photo failed to upload."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UploadChapterPhoto,
        variables: {
          chapterId,
          photoBase64Image
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function addChapterDomain(chapterId, domain) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Chapter does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error creating this chapter page."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: AddChapterDomain,
        variables: {
          chapterId,
          domain
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function editChapter(chapterId, descriptionJson, lat, lng, title, foundedAt) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Chapter does not exist."],
    [BE.ALREADY_EXISTS, "A chapter with this name already exists."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.NOT_EXIST, "The GPS location was not correct."],
    [BE.UPDATE_FAILED, "Error updating this chapter."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: EditChapter,
        variables: {
          chapterId,
          descriptionJson,
          lat,
          lng,
          title,
          foundedAt
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function upsertMeetupGroup(chapterId, meetupGroupId, meetupGroupUrlname, meetupMemberCount) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Chapter does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error creating Meetup group mapping."],
    [BE.UPDATE_FAILED, "Error updating Meetup group mapping."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UpsertMeetupGroup,
        variables: {
          chapterId,
          meetupGroupId,
          meetupGroupUrlname,
          meetupMemberCount
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function removeChapterDomain(chapterId, domain) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Chapter does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.DELETE_FAILED, "Error removing domain from chapter."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: RemoveChapterDomain,
        variables: {
          chapterId,
          domain
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}
